<template>
  <div class="container">

    <!--Breadcrumb 영역 s-->
    <div class="top-nav-container">
      <nav role="navigator">
        <ul class="breadcrumbs list-unstyled hide-sm">
          <li class="breadcrumb"><a href="#">제품</a></li>
          <li class="breadcrumb" v-html="getHead" @click="$router.push(getLandingPath)" role="link"/>
          <li class="breadcrumb" v-html="getTitle" @click="$router.push(getLandingPath)" role="link"/>
          <li class="breadcrumb">관련 논문</li>
        </ul>
      </nav>
    </div>
    <!--Breadcrumb 영역 e-->

    <div class="section">

      <!--Page Title 영역 s-->
      <div class="tit_page_area">
        <h1>관련 논문</h1>
        <p>BMS 제품 관련 주요 논문 내역과 관련 링크를 제공합니다.</p>
      </div>
      <!--Page Title 영역 e-->


      <!--본문 영역 s-->
      <content-area
          v-bind="board"
          @click="check($event)"
          file-name="BMSON-관련논문"
          :bookmark-id="$route.params.clinicalTrialNo"
      />
      <!--본문 영역 e-->


      <!--list view 버튼-->
      <div class="btn_area_right btn_listview">
        <router-link to="../" append>
          <button>List View</button>
        </router-link>
      </div>
      <div class="modal" v-if="showModal">
        <!-- 사이트 이탈 Modal의 내용 -->
        <div class="modal_contents">
          <div class="modal_close close">
            <a @click="closeModal">close</a>
          </div>
          <p class="modal_tit" v-html="`${$options.filters.supTag(board.title)} 논문 제공 포탈사이트로 이동합니다.`"></p>
          <p class="modal_exp" v-html="`현재 이용 중이신 BMSON 웹사이트를 벗어나
             <strong>${$options.filters.supTag(board.title)}</strong> 논문 제공 웹사이트로 이동합니다.<br/>
             해당 웹사이트는 BMSON과 무관한 제3자 웹사이트입니다.
             그리고 해당 웹사이트에서 논문을 조회하시기 위해서는 회원가입 및 유료결제가 필요하실 수 있습니다.<br/>
             BMS는 BMSON 웹사이트 외 외부 웹사이트의 콘텐츠에 대한 책임을 지지 않으며,
             해당 콘텐츠로 인한 손해나 손상에 대한 법적 책임이 없습니다.<br/>
             외부 웹사이트 링크는 단지 BMSON 웹사이트 사용자들에게 편의를 제공하기 위한 목적으로 제공합니다.`"/>
          <div class="modal_btn">
            <span class="btn_modal" @click="closeModal({url: url})">계속하기</span>
            <span class="btn_modal" @click="closeModal">취소</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentArea from "@/components/common/ContentArea";

export default {
  name: "ClinicalTrialView",
  components: {ContentArea},
  created() {
    this.initBoard();
    this.$emit('set-init-tab')
  },
  filters: {
    supTag: function (value) {
      return value.replace(/[®™]/gi, '<sup>$&</sup>');
    }
  },
  data() {
    return {
      board: {
        title: ''
      },
      url: '',
      showModal: false,
    }
  },
  methods: {
    check(event) {
      let {target} = event
      this.findATag(target, event)
    },
    findATag(element, event) {
      if (!element.parentNode) {
        event.preventDefault()
        return false;
      } else if (element.tagName.toLowerCase() !== 'a') {
        return this.findATag(element.parentNode, event)
      } else {
        let {href, origin: targetOrigin} = element
        let {origin: locationOrigin} = location
        if (href) {
          let expUrl = /^http[s]?:\/\//i;
          if (expUrl.test(href)) {
            if (targetOrigin !== locationOrigin) {
              this.openExternalModal(element.href)
            } else {
              return true;
            }
          }
        }
      }
      event.preventDefault()
    },
    openExternalModal(url) {
      this.url = url
      this.showModal = true;
      document.documentElement.classList.add("overflow-y-hidden");
    },
    closeModal({url}) {
      if (url) {
        window.open(url, '_blank')
      }
      document.documentElement.classList.remove("overflow-y-hidden");
      this.showModal = false;
    },
    initBoard() {
      this.$store.dispatch('initBoard', {
        id: this.$route.params.clinicalTrialNo,
        type: 'clinical-trial'
      }).then(board => {
        this.board = board
      })
    }
  },
  props: {},
  computed: {
    getLandingPath() {
      return {
        name: 'Product',
        params: {productName: this.$route.params.productName},
      }
    },
    getHead() {
      return this.$store.state.menu.productMenu[this.$route.params.productName].head
    },
    getTitle() {
      return this.$store.state.menu.productMenu[this.$route.params.productName].title
    },
  },
}
</script>

<style scoped>

</style>
